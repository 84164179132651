import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import React from "react";
import { useMap } from "react-leaflet";
import "../../node_modules/leaflet-geosearch/dist/geosearch.css";

class SearchField extends React.Component {
  componentDidMount() {
    const { map, onLoationChosen } = this.props;

    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider: provider,
      style: "button",
      notFoundMessage: "Sorry, that address could not be found.",
      showMarker: false,
      updateMap: true,
    });
    map.on("geosearch/showlocation", onLoationChosen);
    map.addControl(searchControl);
  }

  render() {
    return null;
  }
}

function withMap(Component) {
  return function WrappedComponent(props) {
    const map = useMap();
    return <Component {...props} map={map} />;
  };
}

export default withMap(SearchField);
