import React from "react";
import { Card, Container, Row } from "react-bootstrap";
import Page from "./Page";

function Imprint() {
  return (
    <Page>
      <Container fluid>
        <Row
          className="justify-content-center"
          style={{
            marginTop: "10vh",
            marginLeft: "5vw",
            marginRight: "5vw",
          }}
        >
          <Card className="mb-3">
            <Card.Body>{renderText()}</Card.Body>
          </Card>
        </Row>
      </Container>
    </Page>
  );
}

const renderText = () => {
  return (
    <>
      <h1>Imprint</h1>
      <p>
        <strong>Information in accordance with &sect; 5 TMG:</strong>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>Matthias Krenz</span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>Bruchweg 4</span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>74252 Massenbachhausen</span>
      </p>
      <p>
        <strong>Contact</strong>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          Email: falkradicalliving@gmail.com
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>Phone: +493053676722</span>
      </p>
      <h2>
        <span style={{ fontWeight: "400" }}>LIABILITY FOR CONTENT</span>
      </h2>
      <p>
        <span style={{ fontWeight: "400" }}>
          We make every effort to keep the information on our Website current,
          but accept no liability whatsoever for the content provided. Pursuant
          to &sect;7 par. 1 of TMG (German Tele-Media Act), the law limits our
          responsibility as a service provider to our own content on these Web
          pages.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          According to &sect;&sect;8 to 10 of TMG, we are not obligated to
          monitor third party information provided or stored on our Website.
          However, we shall promptly remove any content upon becoming aware that
          it violates the law. Our liability in such an instance shall commence
          at the time we become aware of the respective violation.
        </span>
      </p>
      <h2>
        <span style={{ fontWeight: "400" }}>LIABILITY FOR LINKS</span>
      </h2>
      <p>
        <span style={{ fontWeight: "400" }}>
          Our site contains links to third-party Web sites. We have no influence
          whatsoever on the information on these Web sites and accept no
          guarantee for its correctness. The content of such third-party sites
          is the responsibility of the respective owners/providers.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>COPYRIGHTS</span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          The content and works provided on these Web pages are governed by the
          copyright laws of Germany. Duplication, processing, distribution, or
          any form of commercialization of such material beyond the scope of the
          copyright law shall require the prior written consent of its
          respective author or creator.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          Developed by <a href="https://thomasascioglu.com">Thomas Ascioglu</a>
        </span>
      </p>
    </>
  );
};

export default Imprint;
