import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BsPersonFill as PersonIcon } from "react-icons/bs";
import { useSelector } from "react-redux";
import OverlayCardContainer from "../../components/OverlayCardContainer";
import SubscriberCount from "../../components/SubscriberCount";
import "./CollabNote.scss";

function CollabNote({ onCloseButtonClicked }) {
  const selectedChannelData = useSelector(
    (state) => state.collab.selectedChannelData
  );

  return (
    <OverlayCardContainer
      onCloseButtonClicked={onCloseButtonClicked}
      className="overlay-container-collab-note"
    >
      <Row className="justify-content-md-start">
        <Card.Title style={{ paddingLeft: "0" }}>
          {selectedChannelData?.youtube?.snippet?.title}
        </Card.Title>
      </Row>
      <Row>
        <Col xs={{ span: 4 }} className="p-2">
          <Row>
            {selectedChannelData?.youtube?.snippet?.thumbnails?.default ? (
              <a
                style={{ padding: "0" }}
                href={
                  "https://www.youtube.com/channel/" +
                  selectedChannelData?.youtube?.id +
                  "/about"
                }
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <Card.Img
                  variant="top"
                  style={{ width: "100%" }}
                  src={
                    selectedChannelData?.youtube?.snippet?.thumbnails?.default
                      ?.url
                  }
                />
              </a>
            ) : (
              <PersonIcon style={{ fontSize: "100%" }} />
            )}
          </Row>
          <Row className="mt-2">
            <SubscriberCount
              count={selectedChannelData?.youtube?.statistics?.subscriberCount}
            ></SubscriberCount>
          </Row>
        </Col>
        <Col xs={{ span: 7, offset: 1 }} className="p-2">
          <Row>
            <div>
              {selectedChannelData?.collab?.genres?.map((genre) => {
                return (
                  <div className="badge bg-gradient justify-content-between collab-note">
                    {genre}
                  </div>
                );
              })}
            </div>
          </Row>
          <Row className="mt-4">
            {selectedChannelData?.collab?.message ? (
              <div>{selectedChannelData?.collab?.message}</div>
            ) : (
              <div>
                <span>
                  Hey there!
                  <br />
                  Add your channel and leave a custom message. Let people know
                  what kind of collabs you are most interested in!
                  <br />
                  <br />
                  (This channel was added by us, you can contact the creator by
                  clicking on the picture.)
                </span>
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </OverlayCardContainer>
  );
}

export default CollabNote;
