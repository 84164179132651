import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import logo_hands from "../assets/logo_hands.png";
import useBreakpoint from "../useBreakpoint";
import "./Home.scss";
import Page from "./Page";

function Home() {
  const breakpoint = useBreakpoint();

  const renderColabButtons = () => {
    if (breakpoint === "xs" || breakpoint === "sm") {
      return (
        <>
          {/*
        <Row className="justify-content-center" style={{ marginTop: "10vh" }}>
            <Col xs={{ span: 6, offset: 3 }}>
              <Button href="/newtuber" className="button-outlined">
                Find a CoCreator
              </Button>
            </Col>
          </Row>
        */}
          <Row className="justify-content-md-evenly">
            <Col xs={{ span: 6, offset: 3 }}>
              <Button
                className="button-outlined"
                style={{ fontSize: "2.5vh", width: "100%" }}
                href="/collaborate"
              >
                Find Collaborators
              </Button>
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <Row className="justify-content-md-evenly">
          {/*
          <Col md="auto">
            <Button
              href="/newtuber"
              className="button-outlined home-button-collab"
            >
              <div style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
                Find a CoCreator
              </div>
              <p>
                This is the sandbox map, here you can <br />
                find other aspiring creators <br />
                to start a channel with.
              </p>
            </Button>
          </Col>
          */}
          <Col md="auto">
            <Button href="/collaborate" className="button-outlined">
              <div style={{ fontSize: "4vh", marginBottom: "2vh" }}>
                Find a Collab
              </div>
              <p style={{ fontSize: "2vh" }}>
                This is the CreatorMap. Easily find other
                <br />
                creators to collab with in real life <br />
                or virtually.
              </p>
            </Button>
          </Col>
        </Row>
      );
    }
  };

  return (
    <Page>
      <Container fluid>
        <Row className="justify-content-center" style={{ marginTop: "10vh" }}>
          <Image style={{ width: "16vh" }} src={logo_hands} />
        </Row>
        <Row
          className="justify-content-center"
          style={{ marginTop: "10vh", marginBottom: "10vh" }}
        >
          <Col xs="auto">
            <p className="home-main-text">
              from creators
              <br />
              for <b>Creators</b>
            </p>
          </Col>
        </Row>
        {renderColabButtons()}
      </Container>
    </Page>
  );
}

export default Home;
