import axios from "axios";
import { LatLng } from "leaflet";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import LocationPickerMap from "../../components/LocationPickerMap";

function CreateNewTuber({ user, userNewTuber, onCancel, onSuccess, onError }) {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [location, setLocation] = useState(null);
  const [notify, setNotify] = useState(false);
  //const onImageClicked = () => {};

  useEffect(() => {
    setName(userNewTuber?.name);
    setMessage(userNewTuber?.message);
    setLocation(userNewTuber?.location);
    setNotify(userNewTuber?.notify);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = () => {
    if (!user) {
      console.error("Not signed in");
      return;
    }

    user.getIdToken().then((token) => {
      const data = {
        name: name,
        message: message,
        location: location,
        notify: notify,
      };
      axios
        .post(
          "https://us-central1-collab-berlin.cloudfunctions.net/api/newtuber",
          data,
          { headers: { Authorization: "Bearer " + token } }
        )
        .then(function (response) {
          onSuccess(response.data);
        })
        .catch(function (error) {
          onError(error);
        });
    });
  };

  /*
<Col xs="auto">
              <IconContext.Provider
                value={{
                  size: "3rem",
                }}
              >
                <div className="round-icon">
                  <BiImageAdd onClick={onImageClicked} />
                </div>
              </IconContext.Provider>
            </Col>
  */
  return (
    <Card>
      <Card.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>What should they call you?</Form.Label>
            <Form.Control
              type="text"
              defaultValue={userNewTuber?.name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              placeholder="Name"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Your message</Form.Label>
            <Form.Control
              type="text"
              defaultValue={userNewTuber?.message}
              onChange={(message) => {
                setMessage(message.target.value);
              }}
              placeholder="Hey there, I'm looking for someone to start a prank channel with, I live in..."
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Location</Form.Label>
            <LocationPickerMap
              initialLocation={
                new LatLng(
                  Number(userNewTuber?.location?._lat),
                  Number(userNewTuber?.location?._long)
                )
              }
              onLocationChanged={(location) => {
                setLocation(location);
              }}
            ></LocationPickerMap>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              defaultChecked={userNewTuber?.notify}
              onChange={(event) => {
                setNotify(event.target.checked);
              }}
              label="Get notification when NewTuber note gets created in your area"
            />
          </Form.Group>
        </Form>
        <Container fluid>
          <Row className="justify-content-evenly">
            <Col xs="auto">
              <Button variant="primary" onClick={onSave}>
                Save
              </Button>
            </Col>
            <Col xs="auto">
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default CreateNewTuber;
