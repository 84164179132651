import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

///// api/youtuber /////
export const setYoutuber = async (user, data) => {
  return user.getIdToken().then((token) => {
    return axios.post(
      "https://us-central1-collab-berlin.cloudfunctions.net/api/youtuber",
      data,
      { headers: { Authorization: "Bearer " + token } }
    );
  });
};

export const fetchCollabData = async (channelId) => {
  const docRef = doc(db, "youtuber", channelId);
  return getDoc(docRef).then((docSnap) => {
    if (docSnap.exists()) {
      return Promise.resolve(docSnap.data());
    } else {
      return Promise.reject("Couldn't fetchCollabData: No such document!");
    }
  });
};

///// api/admin /////
export const addYoutuber = (user, channelId, location) => {
  return user.getIdToken().then((token) => {
    return axios.post(
      "https://us-central1-collab-berlin.cloudfunctions.net/api/admin/youtuber",
      {
        channelId: channelId,
        location: location,
      },
      { headers: { Authorization: "Bearer " + token } }
    );
  });
};

export const deleteYoutuber = (user, channelId) => {
  user.getIdToken().then((token) => {
    axios.delete(
      "https://us-central1-collab-berlin.cloudfunctions.net/api/admin/youtuber",
      {
        channelId: channelId,
      },
      { headers: { Authorization: "Bearer " + token } }
    );
  });
};

export const addAdminRole = (user, email) => {
  return user.getIdToken().then((token) => {
    return axios.post(
      "https://us-central1-collab-berlin.cloudfunctions.net/api/admin/add",
      {
        email: email,
      },
      { headers: { Authorization: "Bearer " + token } }
    );
  });
};

export const deleteAdminRole = (user, email) => {
  return user.getIdToken().then((token) => {
    return axios.post(
      "https://us-central1-collab-berlin.cloudfunctions.net/api/admin/delete",
      {
        email: email,
      },
      { headers: { Authorization: "Bearer " + token } }
    );
  });
};
