import { combineReducers } from "redux";
import collabReducer from "./collabReducer";
import userReducer from "./userReducer";
import youtubeReducer from "./youtubeReducer";

const rootReducer = combineReducers({
  user: userReducer,
  youtube: youtubeReducer,
  collab: collabReducer,
});

export default rootReducer;
